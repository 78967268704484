import News from './news';
import Pagination from './pagination';

export default class NewsList {
  isError = false;

  list = [];

  seo = null;

  pagination = new Pagination();

  constructor(res) {
    if (!res || !Object.keys(res).length || !res.content) {
      this.isError = true;
    } else {
      this.list = res.content.pages.map(page => new News(page));
      this.pagination = new Pagination(res.content.pagination);
      this.seo = res.extension;
    }
  }

  addItems(res) {
    this.list.push(...res.content.pages.map(item => new News(item)));
    this.pagination = new Pagination(res.content.pagination);
  }

  get isFull() {
    return this.list && this.list.length;
  }
};