import Banner from './banner';

function formatList(images, startIndex) {
  const newImages = [...images];
  const index = images[startIndex] ? startIndex : 0;
  newImages.unshift(newImages.splice(index, 1)[0]);
  return newImages;
};

export default class BannersList {
  isError = false;

  list = [];

  constructor(gallery, startIndex = 0) {
    if (!gallery || !Object.keys(gallery).length || !gallery.content) {
      this.isError = true;
    } else {
      this.list = formatList(gallery.content.elements, startIndex).map(item => new Banner(item));
    }
  }

  get isFull() {
    return this.list && this.list.length;
  }
}