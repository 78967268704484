export default class Pagination {
  page = 1;

  total = null;

  limit = 10;

  constructor(total = null) {
    this.total = total;
  }

  get nextCount() {
    const totalRest = this.total - this.limit * this.page;
    return totalRest > this.limit ? this.limit : totalRest;
  }

  get showLoadMore() {
    return this.total > this.limit * this.page;
  }
};