function replaceFormat(str, format = null) {
  return str.replace('{format}', `${format}`).replace('.jpg', '.webp');
}

export default class Banner {
  banner = {};

  constructor(data = {}) {
    this.banner = data;
  }

  get image() {
    return this.banner.image;
  }

  get original() {
    return replaceFormat(this.image.url);
  }

  get sm() {
    return replaceFormat(this.image.formatUri, 'small_gallery');
  }

  get md() {
    return replaceFormat(this.image.formatUri, 'big_gallery');
  }

  get link() {
    return this.banner.link;
  }

  get isLink() {
    return this.link && this.link.length;
  }
};