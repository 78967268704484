
export default {
  name: 'news-block',
  props: {
    list: {
      type:Array,
      default: () => ([]),
    },
    pagination: {
      type:Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isCompact: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    slicedNews() {
      return this.isCompact ? this.list.slice(0, 3) : this.list;
    },
  },
  methods: {
    loadMore() {
      this.$emit('load-more');
    },
  },
};
