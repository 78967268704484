
import { throttle } from '~/utils';

export default {
  name: 'banners-slider',
  props: {
    images: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      selected: 0,
      timer: null,
      throttleSetImage: throttle(this.setImageOnScroll, 300),
    };
  },
  mounted() {
    if (this.images && this.images.length > 1) {
      this.setAutoNext();
    }
  },
  destroyed() {
    if (this.timer) clearInterval(this.timer);
  },
  methods: {
    setAutoNext() {
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.nextImage(this.selected === this.images.length - 1 ? 0 : this.selected + 1, 'auto');
      }, 7000);
    },
    nextImage(index, type = 'click') {
      if (type === 'click' && this.timer) this.setAutoNext();
      this.selected = index;
      
      this.$refs.slider.scrollTo({
        top: this.isMobile ? 0 : this.$refs.banner[index].offsetTop,
        left: this.isMobile ? this.$refs.banner[index].offsetLeft : 0,
        behavior: 'smooth',
      });
    },
    setImageOnScroll (event) {
      const scrollValue = this.isMobile
        ? event.target.scrollLeft
        : event.target.scrollTop;

      const imageSize = this.isMobile
        ? event.target.scrollWidth / this.images.length
        : event.target.scrollHeight / this.images.length;

      this.selected = Math.round(scrollValue / imageSize);
      this.setAutoNext();
    },
  },
};
