
export default {
  name: 'news-card',
  props: {
    content: {
      type:Object,
      required: true,
    },
    isFull: {
      type:Boolean,
      default:false,
    },
  },
};
