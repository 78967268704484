export default class News {
  news = {};

  constructor(data = {}) {
    this.news = data;
  }

  get author() {
    return this.news.author;
  }

  get created() {
    return this.news.created;
  }

  get url() {
    return this.news.content.url;
  }

  get article() {
    return this.news.content.article;
  }

  get title() {
    return this.news.content.title;
  }

  get image() {
    return this.news.content.img;
  }

  get isFull() {
    return this.news && Object.keys(this.news).length;
  }
};